export default function getContactHref(name: string, contact: string) {
	switch (name) {
		case "twitter": return `https://www.twitter.com/${contact}`;
		case "github": return `https://github.com/${contact}`;
		case "vkontakte": return `https://vk.com/${contact}`;
		case "telegram": return `https://t.me/${contact}`;
		case "email": return `mailto:${contact}`;
		case "linkedin": return `https://www.linkedin.com/in/${contact}`;
		case "instagram": return `https://www.instagram.com/${contact}`;
		case "line": return `line://ti/p/${contact}`;
		case "facebook": return `https://www.facebook.com/${contact}`;
		case "gitlab": return `https://www.gitlab.com/${contact}`;
		case "weibo": return `https://www.weibo.com/${contact}`;
		case "codepen": return `https://www.codepen.io/${contact}`;
		case "youtube": return `https://www.youtube.com/channel/${contact}`;
		case "soundcloud": return `https://soundcloud.com/${contact}`;
		case "medium": return `https://medium.com/${contact}`;
		default: return contact;
	}
}

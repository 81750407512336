import ICONS from "../constants/icons";

const getIcon = (name: string) => {
	switch (name) {
		case "twitter": return ICONS.TWITTER;
		case "github": return ICONS.GITHUB;
		case "vkontakte": return ICONS.VKONTAKTE;
		case "telegram": return ICONS.TELEGRAM;
		case "email": return ICONS.EMAIL;
		case "rss": return ICONS.RSS;
		case "linkedin": return ICONS.LINKEDIN;
		case "instagram": return ICONS.INSTAGRAM;
		case "line": return ICONS.LINE;
		case "facebook": return ICONS.FACEBOOK;
		case "gitlab": return ICONS.GITLAB;
		case "weibo": return ICONS.WEIBO;
		case "codepen": return ICONS.CODEPEN;
		case "youtube": return ICONS.YOUTUBE;
		case "soundcloud": return ICONS.SOUNDCLOUD;
		case "medium": return ICONS.MEDIUM;
		default: return {};
	}
};

export default getIcon;
